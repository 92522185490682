<template>
  <div id="transaction">
    <PanelHeader title="تراکنش ها" />
    <div class="transaction-container">
      <div class="transaction-table-wrapper">
        <Table />
      </div>
    </div>
  </div>
</template>

<script>
import PanelHeader from "@/components/PanelHeader";
import Table from "@/components/Table/Table";

export default {
  name: "Transaction",
  components: { Table, PanelHeader }
};
</script>

<style scoped>
#transaction {
  /*width: calc(100% - 250px);*/
  /*margin-right: auto;*/
  width: 100%;
}

.transaction-container {
  margin-top: 70px;
  padding: 0 10px;
  height: 100vh;
}

@media (min-width: 1140px) {
  #transaction {
    width: calc(100% - 250px);
    margin-right: auto;
  }
}
</style>
